import { combineReducers } from '@reduxjs/toolkit';

import { reducer as user } from './user/reducers';
import { reducer as internalSettings } from './internalSettings/reducers';
import { reducer as locations } from './locations/reducers';

export const reducers = () =>
  combineReducers({
    user,
    internalSettings,
    locations,
  });
